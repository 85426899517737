<template>
	<div class="part-box">
		<div class="row cb part-title">
			<div class="row title"><span class="row title-text">悬赏榜单</span></div>
			<div class="row cc tab">
				<div class="row cc item a" :class="{ active: mode == 0 }" @click="mode = 0">积分</div>
				<div class="line"></div>
				<div class="row cc item a" :class="{ active: mode == 1 }" @click="mode = 1">现金</div>
			</div>
		</div>
		<div class="col list" v-loading="loading">
			<template v-if="!loading" v-for="(item, index) in dataList">
				<div class="row item a" @click="navAskDetail(item)">
					<div class="row left">
						<div class="row cc coin" v-if="mode == 0">
							<el-image :src="require('@/assets/coin.png')" class="img"></el-image>
							<span class="value">{{ item.point }}</span>
						</div>
						<div class="row cc red" v-else>
							<el-image :src="require('@/assets/redpacket.png')" class="img"></el-image>
							<span class="value">{{ item.amount }}元</span>
						</div>
					</div>
					<div class="name text-ellipsis">{{ item.title }}</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['initSuccess'],
	data() {
		return {
			mode: 0,
			loading: true,
			dataList: [],
			success: false
		};
	},
	created() {},
	watch: {
		mode: {
			handler() {
				this.getList();
			},
			immediate: true
		}
	},
	methods: {
		getList() {
			this.dataList == [];
			this.loading = true;
			this.$service
				.post('question/rewardList', { question_id: this.$route.query.id, limit: 6, type: this.mode + 1 })
				.then(res => {
					this.dataList = res.data;
				})
				.finally(() => {
					this.loading = false;
					if (!this.success) {
						this.initSuccess();
						this.success = true;
					}
				});
		},
		navAskDetail(item) {
			console.log(item);
			this.$router.push({
				path: '/ask/detail',
				query: {
					id: item.id
				}
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './common.less';
@import './list.less';
</style>
