<template>
	<div>
		<div class="row line">
			<el-image :src="require('@/pc/assets/jing.png')" class="img"></el-image>
			<div class="row">
				<el-link target="_blank" :underline="false" href="https://beian.miit.gov.cn/">京ICP备2022004232号-1</el-link>
				<span style="margin-left: 4px;">经营性网站备案信息</span>
			</div>
		</div>

		<div class="line">杏田医学（北京）医疗科技有限公司</div>
		<div class="line">营业执照 ©1999-{{ year }}</div>
		<div class="line" style="margin-bottom: 100px;"><el-link target="_blank" :underline="false" href="https://www.12377.cn/">北京互联网违法和不良信息举报中心</el-link></div>
	</div>
</template>

<script>
let year = new Date().getFullYear();
export default {
	inject: ['initSuccess'],
	data() {
		return {
			year
		};
	},
	mounted() {
		this.initSuccess();
	}
};
</script>

<style scoped lang="less">
.img {
	width: 20px;
	height: 20px;
	margin-right: 4px;
}
.line {
	align-items: center;
	color: #999999;
	font-size: 14px;
	line-height: 30px;
}
</style>
