<template>
	<div class="part-box" v-loading="loading">
		<template v-if="setting.official_image">
			<div class="col cc header">
				<el-image :src="$store.getters.oss(setting.official_image)" class="qr">
					<template slot="error">
						<div class="row cc image-slot" style="width: 100%;height: 100%;background-color: #f5f7fa;">
							<i class="el-icon-picture-outline" style="font-size: 24px;color: #909399;"></i>
						</div>
					</template>
				</el-image>
				<div class="name">关注「杏田问答」微信公众号</div>
			</div>
			<div class="col desc">
				<div>加入Q群，和更多同学一同在线讨论问题，</div>
				<div>分享经验，互助回答，共同成长！</div>
				<div style="margin-top: 10px;white-space:pre-line" v-html="setting.qq_group"></div>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	inject: ['initSuccess'],
	data() {
		return {
			loading: true,
			setting: {
				official_image: ''
			}
		};
	},
	computed: {},
	created() {
		this.$service
			.post('auth/setShow')
			.then(res => {
				this.setting = res.data;
			})
			.finally(() => {
				this.loading = false;
				this.initSuccess();
			});
	}
};
</script>

<style scoped lang="less">
@import './wechat.less';
@import './common.less';
.part-box {
	min-height: 435px;
}
</style>
